.bg {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}
.footer {
  background-color: #1976d2;
  bottom: 0;
}
