.main > div > div {
  padding: 0;
}

h1 {
  font-size: 100px;
  color: #7bab4f;
}
.chara_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.chara_name {
  font-size: 70px;
}
.image {
  width: 100%;
  /* height: auto; */
}
